// in App.js
import * as React from "react";
import { Admin, CustomRoutes, Resource, Menu, Layout } from 'react-admin';
import { LoginPage, SetPasswordPage, ForgotPasswordPage } from 'ra-supabase';
import { BrowserRouter, Route } from 'react-router-dom';
import { dataProvider } from './core/dataProvider';
import { authProvider } from './core/authProvider';
import RoomAssets from './pages/rooms/RoomAssets';
import ActiveContractsReport from './pages/report/ActiveContractsReport';
import LabelIcon from '@mui/icons-material/Label';
import pages from './pages';
import { MenuList, MenuItem, Button, Menu as MuiMenu } from '@mui/material';
export const MyMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Menu>
            <Menu.ResourceItems />

            <div style={{ marginLeft: '15px' }}>
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    startIcon={<LabelIcon />}
                >
                    REPORT
                </Button>
                <MuiMenu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <Menu.Item to="/report/active-contracts" primaryText="Active Contracts" />
                </MuiMenu>
            </div>
        </Menu>
    );
};
const MyLayout = ({ children }) => (
    <Layout menu={MyMenu}>
        {children}
    </Layout>
);
export default function App() {
    return (
        <Admin
            dataProvider={dataProvider}
            authProvider={authProvider}
            layout={MyLayout}
            loginPage={LoginPage}
        >
            {permissions => [
                <CustomRoutes noLayout>
                    <Route
                        path={SetPasswordPage.path}
                        element={<SetPasswordPage />}
                    />
                    <Route
                        path={ForgotPasswordPage.path}
                        element={<ForgotPasswordPage />}
                    />
                </CustomRoutes>,
                <CustomRoutes>
                    <Route path="/rooms/:roomId/assets" element={<RoomAssets />} />
                    <Route path="/report/active-contracts" element={<ActiveContractsReport />} />
                </CustomRoutes>,
                ...pages.filter(page => !!permissions && permissions.some(permission => permission.resource === '*' || permission.resource === page.name)).map((page, i) => (<Resource key={i} {...page} />))
            ]}
        </Admin>
    );
} 