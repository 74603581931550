import React from 'react';
import {
    List,
    useListContext,
} from 'react-admin';

import { Table, TableHead, TableRow, TableCell, TableBody, TableFooter } from '@mui/material';

interface ReportRecord {
    room_name: string;
    rent_fee: number;
    common_service_fee: number;
    parking_fee: number;
    occupancy_surcharge: number;
    water_fee: number;
    first_electric_reading: number;
    total_amount: number;
    bike_count: number;
    customer_count: number;
}

// Utility function to format numbers
const formatNumber = (value: number | null | undefined): string => {
    if (value === null || value === undefined || value === 0) {
        return '';
    }
    return value.toLocaleString('en-US');
};


const CustomTable: React.FC = () => {
    const { data, isLoading } = useListContext<ReportRecord>();

    if (isLoading || !data) return <p>Loading...</p>;

    // Calculate totals dynamically
    const totals = data.reduce(
        (acc, record) => ({
            rent_fee: acc.rent_fee + record.rent_fee,
            common_service_fee: acc.common_service_fee + record.common_service_fee,
            parking_fee: acc.parking_fee + record.parking_fee,
            occupancy_surcharge: acc.occupancy_surcharge + record.occupancy_surcharge,
            water_fee: acc.water_fee + record.water_fee,
            first_electric_reading: acc.first_electric_reading + record.first_electric_reading,
            total_amount: acc.total_amount + record.total_amount,
            bike_count: acc.bike_count + record.bike_count,
            customer_count: acc.customer_count + record.customer_count,
        }),
        {
            customer_count: 0,
            bike_count: 0,
            rent_fee: 0,
            common_service_fee: 0,
            parking_fee: 0,
            occupancy_surcharge: 0,
            water_fee: 0,
            first_electric_reading: 0,
            total_amount: 0,
        }
    );

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Room Name</TableCell>
                    <TableCell>Customer Count</TableCell>
                    <TableCell>Bike Count</TableCell>
                    <TableCell>Parking Fee</TableCell>
                    <TableCell>Rent Fee</TableCell>
                    <TableCell>Common Service Fee</TableCell>
                    <TableCell>Occupancy Surcharge</TableCell>
                    <TableCell>Water Fee</TableCell>
                    <TableCell>Electric Reading</TableCell>
                    <TableCell>Total Amount</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((record, index) => (
                    <TableRow key={index}>
                        <TableCell>{record.room_name}</TableCell>
                        <TableCell>{formatNumber(record.customer_count)}</TableCell>
                        <TableCell>{formatNumber(record.bike_count)}</TableCell>
                        <TableCell>{formatNumber(record.parking_fee)}</TableCell>
                        <TableCell>{formatNumber(record.rent_fee)}</TableCell>
                        <TableCell>{formatNumber(record.common_service_fee)}</TableCell>
                        <TableCell>{formatNumber(record.occupancy_surcharge)}</TableCell>
                        <TableCell>{formatNumber(record.water_fee)}</TableCell>
                        <TableCell>{formatNumber(record.first_electric_reading)}</TableCell>
                        <TableCell>{formatNumber(record.total_amount)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TableCell>Total</TableCell>
                    <TableCell>{formatNumber(totals.customer_count)}</TableCell>
                    <TableCell>{formatNumber(totals.bike_count)}</TableCell>
                    <TableCell>{formatNumber(totals.parking_fee)}</TableCell>
                    <TableCell>{formatNumber(totals.rent_fee)}</TableCell>
                    <TableCell>{formatNumber(totals.common_service_fee)}</TableCell>
                    <TableCell>{formatNumber(totals.occupancy_surcharge)}</TableCell>
                    <TableCell>{formatNumber(totals.water_fee)}</TableCell>
                    <TableCell>{formatNumber(totals.first_electric_reading)}</TableCell>
                    <TableCell>{formatNumber(totals.total_amount)}</TableCell>
                </TableRow>
            </TableFooter>
        </Table>
    );
};

const ActiveContractsReport: React.FC = () => {
    return (
        <List
            title="Active Contracts Report"
            resource="active_contracts_report"
            sort={{ field: 'room_name', order: 'ASC' }} // Default sort by room_name
            perPage={50} // Set default perPage to 50
            bulkActionButtons={false}
        >
            <CustomTable />
        </List>
    );
};

export default ActiveContractsReport;
