import React, { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { useRecordContext, useNotify } from 'react-admin';
import dayjs from 'dayjs';
import { supabaseClient as supabase } from "../../../core/supabase";
import numberToVietnameseText from '../n2vi';
import { ServiceFeeId } from '../../../ts-types/types';

interface PrintShowButtonProps {
}

const PrintShowButton: React.FC<PrintShowButtonProps> = () => {
    const apiUrl = process.env.REACT_APP_API_URL + '/contract/print/'; // Replace with your API URL
    const notify = useNotify();
    const record = useRecordContext(); // Access the current record for display
    const [loading, setLoading] = useState(false);

    if (!record) return null; // If no record is loaded, do not display button

    const handlePrint = async () => {
        // Helper functions
        const formatDate = (date: string | Date) => (date ? dayjs(date).format('ngày DD [tháng] MM [năm] YYYY') : 'N/A');
        const formatDuration = (term: string) =>
            term
                .replaceAll('months', 'tháng')
                .replaceAll('month', 'tháng')
                .replaceAll('years', 'năm')
                .replaceAll('year', 'năm');

        const primaryCustomer = record.contract_customers[0];
        const occupancySurcharge = record.contract_service_fees.find(
            (f: any) => f.service_fee_id === ServiceFeeId.OccupancySurcharge
        )?.negotiated_fee;
        const rentfee = record.contract_service_fees.find((f: any) => f.fee_type === 'RENT').negotiated_fee;

        // Data for printing
        const printData = {
            start_date: formatDate(record.start_date),
            customer_name: primaryCustomer?.customer_name || "N/A",
            customer_dob: formatDate(primaryCustomer?.customer_dob),
            customer_id_num: primaryCustomer?.customer_identity_number || "N/A",
            customer_id_date: formatDate(primaryCustomer?.customer_id_issue_date),
            customer_address: primaryCustomer?.customer_address || "N/A",
            customer_phone: primaryCustomer?.phone_number || "N/A",
            house_address: `${record.room_details.house.address}, P. ${record.room_details.house.ward}, Q. ${record.room_details.house.district}, ${record.room_details.house.city}`,
            room_name: record.room_details.room_name,
            customer_count: record.contract_customers ? record.contract_customers.length : "N/A",
            room_addition_people_fee: occupancySurcharge || "Không nhận thêm người",
            contract_duration: formatDuration(record.contract_duration || "N/A"),
            contract_start_date: formatDate(record.start_date),
            contract_end_date: formatDate(record.end_date),
            rent_fee: rentfee,
            rent_fee_text: numberToVietnameseText(rentfee),
            deposit_amount: record.deposit_amount || "N/A",
            deposit_amount_text: record.deposit_amount ? numberToVietnameseText(record.deposit_amount) : "N/A",
            electric_meter_reading: record.electric_meter_reading || "N/A",
            room_parking_lot: record.room_details.allowed_parking_lots || "N/A",
            room_free_parking_lot: record.room_details.available_parking_lots || "N/A",
            contract_payment_day: record.payment_day || "N/A",
        };

        try {
            setLoading(true);
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + supabase.changedAccessToken,
                },
                body: JSON.stringify(printData),
            });

            if (!response.ok) {
                throw new Error('Error generating PDF');
            }

            const pdfBlob = await response.blob();
            const pdfUrl = window.URL.createObjectURL(pdfBlob);
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = `contract_${record.id}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error during printing:', error.message);
            notify('Error generating PDF', { type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={handlePrint}
            disabled={loading}
            startIcon={<PrintIcon />}
        >
            {loading ? <CircularProgress size={24} /> : 'Print Contract'}
        </Button>
    );
};

export default PrintShowButton;
