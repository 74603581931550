import React, { useState, useEffect } from 'react';
import { Show, SimpleShowLayout, TextField, ShowProps, TopToolbar, EditButton, useRecordContext, useDataProvider } from 'react-admin';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography } from '@mui/material';
import ContractShowSection from './ContractShowSection';
import ServiceFeeDisplaySection from './ServiceFeeDisplaySection';
import CustomerDisplaySection from './CustomerDisplaySection';
import PrintShowButton from './PrintShowButton';
import RegenerateInvoiceButton from './RegenerateInvoiceButton';
const RoomShowActions: React.FC = () => {
    const record = useRecordContext();

    if (!record) return null;

    return (
        <TopToolbar>
            <EditButton />
            <PrintShowButton />
            <RegenerateInvoiceButton />
        </TopToolbar>
    );
};

const ContractShow: React.FC = (props) => {
    const dataProvider = useDataProvider();

    const [openErrorDialog, setOpenErrorDialog] = useState(false); // Manage the dialog state
    const [errorMessage, setErrorMessage] = useState<string | null>(null); // Store the error message

    const handleDialogClose = () => {
        setOpenErrorDialog(false);
        setErrorMessage(null);
    };

    return (
        <div>
            <Show
                {...props}
                queryOptions={{ meta: { useContractDetails: true } }}
                actions={<RoomShowActions />}
            >
                <SimpleShowLayout>
                    {/* Contract Information Section */}
                    <Typography variant="h5" align="left" sx={{ marginTop: '0px' }}>
                        Contract Information
                    </Typography>
                    <ContractShowSection />

                    {/* Service Fees Section */}
                    <Typography variant="h5" align="left" sx={{ marginTop: '0px', marginBottom: '0px' }}>
                        Service Fees
                    </Typography>
                    <ServiceFeeDisplaySection />

                    {/* Customer Information Section */}
                    <Typography variant="h5" align="left" sx={{ marginTop: '0px' }}>
                        Customer Information
                    </Typography>
                    <CustomerDisplaySection />

                    {/* Print Contract Button */}
                    {/* <PrintContractButton /> */}
                </SimpleShowLayout>
            </Show>

            {/* Error Dialog */}
            <Dialog open={openErrorDialog} onClose={handleDialogClose}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <DialogContentText>{errorMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ContractShow;
