import React, { useEffect, useState } from 'react';
import { SelectInput, SelectInputProps } from 'react-admin';
import { supabaseClient as supabase } from '../core/supabase';

/**
 * ContractFilterInput component that extends SelectInput and fetches active contracts.
 */
const ContractFilterInput: React.FC<SelectInputProps> = (props) => {
    const [contractChoices, setContractChoices] = useState<any[]>([]);

    useEffect(() => {
        // Fetch active contracts with room names
        const fetchContracts = async () => {
            const { data, error } = await supabase
                .from('contracts')
                .select('id, room_id, rooms(room_name)')
                .eq('status', 'ACTIVE');

            if (error) {
                console.error('Error fetching active contracts:', error);
            } else {
                // Map the data into choices for the SelectInput
                const choices = data.map((contract: any) => ({
                    id: contract.id, // contract_id as value
                    name: contract.rooms.room_name, // room_name as display text
                }));
                setContractChoices(choices);
            }
        };

        fetchContracts();
    }, []);

    return (
        // Pass down props and choices to the SelectInput component
        <SelectInput {...props} choices={contractChoices} />
    );
};

export default ContractFilterInput;
