import React, { useState } from 'react';
import { Button, useDataProvider } from 'react-admin';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton, Typography } from '@mui/material';
import PaidIcon from '@mui/icons-material/Done';
import CancelButton from '@mui/icons-material/Cancel';
import ConfirmButton from '@mui/icons-material/Check';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PasteIcon from '@mui/icons-material/ContentPaste';
import { supabaseClient as supabase } from '../../core/supabase'; // Importing the Supabase client

/**
 * PaidButton component - allows user to mark the invoice as paid with proof of payment.
 */
const PaidButton: React.FC<{ invoiceId: number; refresh: () => void }> = ({ invoiceId, refresh }) => {
    const [open, setOpen] = useState(false);
    const [paidProof, setPaidProof] = useState<File | null>(null);
    const [clipboardImage, setClipboardImage] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [error, setError] = useState('');
    const dataProvider = useDataProvider();

    // Open the dialog to submit proof of payment
    const handleOpen = () => setOpen(true);

    // Close the dialog
    const handleClose = () => {
        setOpen(false);
        setPaidProof(null);
        setClipboardImage(null);
        setImagePreview(null);
        setError('');
    };

    // Handle file upload (image of payment proof)
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setPaidProof(file);
            setError('');
            showImagePreview(file); // Show the selected image preview
        }
    };

    // Handle clipboard image paste
    const handlePaste = (event: React.ClipboardEvent) => {
        const items = event.clipboardData.items;
        for (const item of items) {
            if (item.type.includes('image')) {
                const file = item.getAsFile();
                if (file) {
                    setClipboardImage(file);
                    setError('');
                    showImagePreview(file); // Show the pasted image preview
                }
            }
        }
    };

    // Show the image preview
    const showImagePreview = (file: File) => {
        const reader = new FileReader();
        reader.onload = (e) => setImagePreview(e.target?.result as string);
        reader.readAsDataURL(file);
    };

    // Handle payment confirmation
    const handleConfirmPaid = async () => {
        const imageFile = clipboardImage || paidProof;

        if (!imageFile) {
            setError('Please provide a proof of payment.');
            return;
        }

        try {
            // Upload image to Supabase bucket 'tigon'
            const fileName = `${invoiceId}-${new Date().getTime()}`;
            const path = `payment_proofs/${fileName}`;
            const { data, error: uploadError } = await supabase.storage
                .from('tigon')
                .upload(path, imageFile);

            if (uploadError) {
                setError('Error uploading image. Please try again.');
                return;
            }
            // Mark the invoice as paid, update the paid_date and paid_proof_url
            await dataProvider.update('invoices', {
                id: invoiceId,
                data: {
                    status: 'PAID',
                    paid_date: new Date().toISOString(),
                    paid_proof_url: path,
                },
                previousData: {}
            });

            refresh(); // Refresh the page
            handleClose(); // Close the dialog
        } catch (err) {
            setError('Error processing payment. Please try again.');
        }
    };

    return (
        <>
            <Button label="Paid" onClick={handleOpen}>
                <PaidIcon />
            </Button>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Provide Proof of Payment</DialogTitle>
                <DialogContent onPaste={handlePaste}>
                    <Typography gutterBottom>Paste an image from the clipboard or upload a file.</Typography>

                    {/* Upload Image Button */}
                    <IconButton component="label" color="primary">
                        <CloudUploadIcon />
                        <input hidden type="file" accept="image/*" onChange={handleFileChange} />
                        <Typography variant="body2">Upload Image</Typography>
                    </IconButton>

                    {/* Paste Button */}
                    <IconButton color="primary">
                        <PasteIcon />
                        <Typography variant="body2">Paste Image (Press Ctrl+V)</Typography>
                    </IconButton>

                    {/* Show Error */}
                    {error && <Typography color="error">{error}</Typography>}

                    {/* Show Selected or Pasted Image Preview */}
                    {imagePreview && (
                        <div>
                            <Typography variant="subtitle1" gutterBottom>
                                Preview:
                            </Typography>
                            <img src={imagePreview} alt="Payment proof" style={{ width: '100%', maxHeight: '300px', objectFit: 'contain' }} />
                        </div>
                    )}
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>
                        <CancelButton /> Cancel
                    </Button>
                    <Button onClick={handleConfirmPaid}>
                        <ConfirmButton /> Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PaidButton;
